import { Component, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { User } from '../../models/user';
import { AuthenticationService } from '../../services/authentication.service';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {
  name: string;
  user: User;
  public config: PerfectScrollbarConfigInterface = {};
  constructor(private modalService: NgbModal, private auth: AuthenticationService, private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.user = this.auth.currentUserValue;
    this.cdRef.detectChanges();
    const set = function() {
      const width =
        window.innerWidth > 0 ? window.innerWidth : this.screen.width;
      const topOffset = 0;
      if (width < 1170) {
        $('#main-wrapper').addClass('mini-sidebar');
      } else {
        $('#main-wrapper').removeClass('mini-sidebar');
      }
    };
    $(window).ready(set);
    $(window).on('resize', set);

    $('.search-box a, .search-box .app-search .srh-btn').on(
      'click',
      function() {
        $('.app-search').toggle(200);
      }
    );

    $('body').trigger('resize');
  }

  logout() {
    this.auth.logout();
  }
}
