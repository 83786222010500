import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  color = 'blue';
  showSettings = false;
  showMinisidebar = false;
  showDarktheme = false;
  showRtl = false;
  year: any;

  public config: PerfectScrollbarConfigInterface = {};

  constructor(public router: Router, public auth: AuthenticationService) {}

  ngOnInit() {
    this.year = new Date().getFullYear();
  }
}
