import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Panel',
    icon: 'mdi mdi-gauge',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    role_id: 4,
    submenu: [],
  },
  {
    path: '/users/list',
    title: 'Usuarios',
    icon: 'mdi mdi-account',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    role_id: 1,
    submenu: [],
  },
  {
    path: '',
    title: 'Tickets',
    icon: 'mdi mdi-arrange-send-backward',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    role_id: 1,
    submenu: [
      /* MODIFICACIÓN: Mantener únicamente un listado de tickets mostrando solo los del día actual
      {
        path: '/tickets/list/0',
        title: 'Sin Validar',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/tickets/list/1',
        title: 'Validados',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/tickets/validate/',
        title: 'Validar Tickets',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
       */
      {
        path: '/tickets/list/',
        title: 'Tickets',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        role_id: 1,
        submenu: []
      },
      {
        path: '/tickets/stats/',
        title: 'Estadísticas',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        role_id: 1,
        submenu: []
      },
      /* MODIFICACIÓN: Añadida nueva opción para mostrar un resumen global de las estadísticas de los tickets */
      {
        path: '/tickets/statsGlobal/',
        title: 'Estadísticas generales',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        role_id: 1,
        submenu: []
      }
    ]
  },
  {
    path: '/messages/list',
    title: 'Mensajes',
    icon: 'mdi mdi-message',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    role_id: 4,
    submenu: []
  },
  {
    path: '/events/calendar',
    title: 'Calendario',
    icon: 'mdi mdi-calendar',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    role_id: 4,
    submenu: [
    ]
  }
];
