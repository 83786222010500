import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {FullComponent} from './layouts/full/full.component';
import {AuthGuard} from './guards/auth.guard';
import {LoginComponent} from './login/login.component';
import {Role} from './models/role';

export const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        loadChildren: './starter/starter.module#StarterModule',
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin, Role.Director] }
      },
      {
        path: 'users',
        loadChildren: './users/users.module#UsersModule',
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] }
      },
      {
        path: 'tickets',
        loadChildren: './tickets/tickets.module#TicketsModule',
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] }
      },
      {
        path: 'messages',
        loadChildren: './messages/messages.module#MessagesModule',
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin, Role.Director] }
      },
      {
        path: 'events',
        loadChildren: './events/events.module#EventsModule',
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin, Role.Director] }
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), NgbModule],
  exports: [RouterModule]
})
export class AppRoutingModule {}
